import React, { useState } from 'react'
import NormalInput from '../../library/inputs/NormalInput'
import NormalButton from '../../library/NormalButton'
import axios from 'axios';
import { toast } from 'react-toastify';
import TextArea from '../../library/inputs/TextArea';
import ConfirmationModal from '../../library/modal/ConfirmationModal';

export default function StudentForm() {
  const [loading, setLoading] = useState(false)
  const [studentData, setStudentData] = useState({
    name: "",
    email: "",
    password: "",
    dob: "",
    phoneNumber: "",
    alternatePhoneNumber: "",
    avatar: "",
    //   "courses": ["represent", "system", "already"],
    enrollmentDate: "",
    collegeName: "",
    highestQualification: "",
    //   "referral_id": "EDXz6l0s",
    //   "batch": 9,
    address: ""

  })



  const handelChange = (key, value) => {
    setStudentData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handelCreate = async () => {
    if (studentData?.name == "" || studentData?.email == "" || studentData?.dob == "" || studentData?.phoneNumber == "" || studentData?.password == "") {
      toast.warning("Please fill all the mandatory fields");
      return;
    }

    setLoading(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/student/create`, studentData);
      const msg = response?.data?.message;
      const status = response?.data?.status;

      if (status) {
        toast.success(msg)
        handelEmptyInput()
      } else {
        toast.error(msg)
      }
    } catch (err) {
      toast.error(err?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  const handelEmptyInput = () => {
    setStudentData({
      name: "",
      email: "",
      password: "",
      dob: "",
      phoneNumber: "",
      alternatePhoneNumber: "",
      avatar: "",
      //   "courses": ["represent", "system", "already"],
      enrollmentDate: "",
      collegeName: "",
      highestQualification: "",
      //   "referral_id": "EDXz6l0s",
      //   "batch": 9,
      address: ""
    })
  }

  return (
    <div>
      
      <div className='grid gap-3 p-6 grid-cols-2' >
        <NormalInput
          label='*Name'
          value={studentData?.name}
          onchange={(val) => {
            handelChange('name', val);
          }} />
        <NormalInput
          label='*Email'
          value={studentData?.email}
          onchange={(val) => {
            handelChange('email', val);
          }} />

        <NormalInput
          label='*Dob'
          type='date'
          value={studentData?.dob}
          onchange={(val) => {
            handelChange('dob', val);
          }} />
        <NormalInput
          label='*Phone Number'
          value={studentData?.phoneNumber}
          onchange={(val) => {
            handelChange('phoneNumber', val);
          }} />
        <NormalInput
          label='Alternate Phone Number'
          value={studentData?.alternatePhoneNumber}
          onchange={(val) => {
            handelChange('alternatePhoneNumber', val);
          }} />
        <NormalInput
          label='*Password'
          value={studentData?.password}
          onchange={(val) => {
            handelChange('password', val);
          }} />
        <NormalInput
          label='College Name'
          value={studentData?.collegeName}
          onchange={(val) => {
            handelChange('collegeName', val);
          }} />
        <NormalInput
          label='Enrollment Date'
          type='date'
          value={studentData?.enrollmentDate}
          onchange={(val) => {
            handelChange('enrollmentDate', val);
          }} />
        <NormalInput
          label='Highest Qualification'
          value={studentData?.highestQualification}
          onchange={(val) => {
            handelChange('highestQualification', val);
          }} />

        <TextArea
          label='Address'
          value={studentData?.address}
          onchange={(val) => {
            handelChange('address', val);
          }} />

      </div>

      <div className='flex justify-end gap-2 p-3' >
        <NormalButton label='Cancel' rounded type='outline' onClick={handelEmptyInput} />
        <NormalButton label='Save' rounded onClick={handelCreate} isLoading={loading} />
      </div>
    </div>
  )
}
