import React from 'react';
import Sidebar from '../components/common/Sidebar';
import AuthNavbar from '../components/common/AuthNavbar';

const DashboardLayout = ({ children }) => (
    <React.Fragment>
        {/* <AuthNavbar /> */}
        <div className='absolute top-0 bottom-0 left-0 right-0 overflow-hidden flex'>
            <Sidebar />
            <div className='h-full w-full overflow-auto' >
                {children}
            </div>
        </div>
    </React.Fragment>
);

export default DashboardLayout;
