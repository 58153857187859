import React, { useEffect, useState } from 'react'
import List from '../../components/library/List';
import Plus from '../../assets/Icons/Plus'
import NormalButton from '../../components/library/NormalButton';
import StudentForm from '../../components/dashboard/students/StudentForm';
import SingleArrow from '../../assets/Icons/SingleArrow';
import axios from 'axios';
import Edit from '../../assets/Icons/Edit';
import Delete from '../../assets/Icons/Delete';
import ConfirmationModal from '../../components/library/modal/ConfirmationModal';
import { toast } from 'react-toastify';


export default function Students() {
  const [students, setStudents] = useState([])
  const [showFrom, setShowFrom] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState("")
  const [delLoad, setDelLoad] = useState(false)
  const [getStuLoad, setGetStuLoad] = useState(false)

  const columns = [
    { name: 'Name', key: 'name' },
    { name: 'Email', key: 'email' },
    { name: 'Phone', key: 'phoneNumber' },
    { name: 'College', key: 'collegeName' },
    { name: 'Highest Qualification', key: 'highestQualification' },
    {
      name: 'Edit',
      // headerAction: () => (
      //   <div className="flex items-center gap-2" >
      //     <span>Actions</span>
      //     <Arrow />
      //   </div>
      // ),
      action: (row) => (
        <button onClick={() => alert(`Action clicked for ${row.name}`)}>
          <Edit />
        </button>
      )
    },
    {
      name: 'Delete',
      // headerAction: () => (
      //   <div className="flex items-center gap-2" >
      //     <span>Actions</span>
      //     <Arrow />
      //   </div>
      // ),
      action: (row) => (
        <button onClick={() => {
          setId(row?._id)
          setIsModalOpen(true)
        }}>
          <Delete />
        </button>
      )
    }

  ];

  const getStudentData = async () => {
    setGetStuLoad(true)
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/student/get-all-students`);
    if (response?.data?.status) {
      setStudents(response?.data?.data)
    }
    setGetStuLoad(false)
  }

  useEffect(() => {
    getStudentData();
  }, [])

  const handleConfirm = async () => {
    setDelLoad(true)
    const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/student/delete/${id}`);
    if (response?.data?.status) {
      toast.success(response?.data?.message)
      setIsModalOpen(false);
      getStudentData()
    } else {
      toast.error("Something went wrong")
      setIsModalOpen(false);
    }
    setDelLoad(false)
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setId("")
  };

  return (
    <div>
      <ConfirmationModal
        loading={delLoad}
        isOpen={isModalOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        title="Delete Student"
        message="Are you sure you want to delete this student? This action cannot be undone."
      />
      <div className='flex justify-end p-3 sticky top-0 bg-white' >
        <div className='w-fit' >
          <NormalButton color='qlithNavy' label={showFrom ? 'Back' : 'Add Student'} rounded icon={!showFrom ? <Plus /> : <SingleArrow rotate='down' />} onClick={() => setShowFrom(!showFrom)} />
        </div>
      </div>
      {
        showFrom ? <StudentForm /> :
          <div className='p-2' >
            <List columns={columns} data={students} loading={getStuLoad} />
          </div>
      }
    </div>
  )
}
