import Login from "../screens/nonAuth/Login";
import Register from "../screens/nonAuth/Register";
import Dashboard from "../screens/auth/Dashboard";
import Setting from "../screens/auth/Setting";
import About from "../screens/users/about/About";
import Contact from "../screens/users/contact/Contact";
import ForgotPassword from "../screens/nonAuth/ForgotPassword";
import Home from "../screens/users/home/Home";
import Courses from "../screens/users/courses/Courses";
import Carrer from "../screens/users/carrer/Carrer";
import JobDetails from "../screens/users/carrer/JobDetails";
import CourseDetail from "../screens/users/courses/CourseDetails";
import Services from "../screens/users/services/Services";
import Students from "../screens/auth/Students";
import Income from "../screens/auth/Income";
import Expenditure from "../screens/auth/Expenditure";
import Employees from "../screens/auth/Employees";
import CoursesDashboard from "../screens/auth/CoursesDashboard";
import Apply from "../screens/users/carrer/Apply";

export const authRoutes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/settings",
    component: <Setting />,
  },
  {
    path: "/students",
    component: <Students />,
  },
  {
    path: "/income",
    component: <Income />,
  },
  {
    path: "/expenditure",
    component: <Expenditure />,
  },
  {
    path: "/courses-dashboard",
    component: <CoursesDashboard />,
  },
  {
    path: "/employees",
    component: <Employees />,
  },
];

export const nonAuthRoutes = [
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/register",
    component: <Register />,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
  },
];

export const userRoutes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/about",
    component: <About />,
  },
  {
    path: "/contact",
    component: <Contact />,
  },
  {
    path: "/courses",
    component: <Courses />,
  },
  {
    path: "/courses/:id",
    component: <CourseDetail />,
  },
  {
    path: "/carrer",
    component: <Carrer />,
  },
  {
    path: "/carrer/:id",
    component: <JobDetails />,
  },
  {
    path: "/services",
    component: <Services />,
  },
  {
    path: "/carrer/:id/apply",
    component: <Apply />,
  },
];
