import React from 'react';

export default function Arrow({ rotate = "down", size = "20", className = "", ariaLabel = "Arrow icon" }) {
    const directionMap = {
        left: "rotate-90",
        right: "-rotate-90",
        up: "rotate-0",
        down: "rotate-180",
    };

    const rotationClass = directionMap[rotate] || "rotate-0";

    return (
        <div className={`transform ${rotationClass}`}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className={`size-6 ${className}`}
                style={{ width: size, height: size }}
                aria-label={ariaLabel}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
            </svg>
        </div>
    );
}
