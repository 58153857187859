import React from 'react';

export default function SingleArrow({ rotate = "up", size = "20", className = "", ariaLabel = "Single arrow" }) {
    const directionMap = {
        left: "rotate-90",
        right: "-rotate-90",
        up: "rotate-0",
        down: "rotate-180",
    };

    const rotationClass = directionMap[rotate] || "rotate-0";

    return (
        <div className={`transform ${rotationClass}`}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className={`size-6 ${className}`}
                style={{ width: size, height: size }}
                aria-label={ariaLabel}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                />
            </svg>
        </div>
    );
}
