import React from 'react';
import NormalButton from '../NormalButton';

export default function ConfirmationModal({
    isOpen,
    onClose,
    onConfirm,
    loading,
    title = "Are you sure?",
    message = "Do you want to proceed?",
}) {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-96">
                <h2 className="text-lg font-semibold text-qlithNavy">{title}</h2>
                <p className="mt-2 text-sm text-gray-600">{message}</p>
                <div className="mt-4 flex justify-end gap-3">
                    <NormalButton
                        type='outline'
                        rounded
                        label="No"
                        onClick={onClose}
                    />
                    <NormalButton
                        label="Yes"
                        rounded
                        onClick={onConfirm}
                        isLoading={loading}
                    />
                </div>
            </div>
        </div>
    );
}
