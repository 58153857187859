import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FiChevronUp, FiChevronDown, FiMenu, FiX } from "react-icons/fi";
import { motion } from "framer-motion";
import Logo from "../../assets/Logo.svg";
import Login from "../../assets/Icons/Login.svg";
import { useLocation } from "react-router-dom";

const MainNavbar = () => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null); // Track which dropdown is open
  const [scrollPosition, setScrollPosition] = useState(0);
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const menuRef = useRef(null);
  const location = useLocation();
  const pathname = location?.pathname;

  // Toggle specific dropdown
  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // Track scroll position
  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenDropdownIndex(null); // Close all dropdowns when clicking outside
        setHamburgerOpen(false); // Close hamburger menu as well
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav
      className={`fixed top-0 w-full transition-all duration-300 z-50 md:px-20 px-10 ${
        scrollPosition > 40
          ? ` bg-opacity-90 backdrop-blur-md shadow-lg`
          : "bg-blue-900 bg-opacity-0"
      }`}
    >
      <div className="flex justify-between items-center py-4">
        <div className="flex items-center">
          <Link to="/" className="text-white text-2xl font-bold">
            <img src={Logo} alt="" className="md:w-32 w-16" />
          </Link>
        </div>
        <div className="hidden lg:flex items-center space-x-4">
          {/* Desktop Menu Items */}
          {menuItems.map((item, index) => (
            <div key={index} className="relative">
              {item.options ? (
                <div ref={menuRef}>
                  {/* Dropdown menu (Services, Career, etc.) */}
                  <button
                    className={`text-white px-4 py-2 rounded-md flex items-center focus:outline-none hover:text-qlithSky`}
                    onClick={() => toggleDropdown(index)}
                  >
                    {item.title}
                    <span className="ml-2">
                      {openDropdownIndex === index ? (
                        <FiChevronUp className="w-5 h-5" />
                      ) : (
                        <FiChevronDown className="w-5 h-5" />
                      )}
                    </span>
                  </button>
                  {openDropdownIndex === index && (
                    <motion.ul
                      className={`absolute text-white bg-gray-800 mt-2 w-40 rounded-md shadow-lg py-2`}
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      transition={{ duration: 0.2 }}
                    >
                      {item.options.map((option, idx) => (
                        <li key={idx}>
                          <Link
                            to={option.path}
                            className="block px-4 py-2"
                            onClick={() => setOpenDropdownIndex(null)}
                          >
                            {option.title}
                          </Link>
                        </li>
                      ))}
                    </motion.ul>
                  )}
                </div>
              ) : (
                <Link
                  to={item.path}
                  className={` ${
                    pathname?.split("/").includes(item.path.substring(1))
                      ? "text-qlithSky"
                      : "text-white"
                  } px-4 py-2 rounded-md hover:text-qlithSky `}
                  style={{ textShadow: "1px 1px 2px gray" }}
                >
                  {item.title}
                </Link>
              )}
            </div>
          ))}
          <Link
            to="/login"
            className="flex text-white bg-qlithSky rounded items-center py-1 px-2"
          >
            <p>Login</p>
            <img src={Login} alt="" />
          </Link>
        </div>
        {/* Hamburger Menu for Mobile */}
        <button
          className="lg:hidden text-white"
          onClick={() => setHamburgerOpen((prev) => !prev)}
        >
          {hamburgerOpen ? <FiX size={24} /> : <FiMenu size={24} />}
        </button>
      </div>

      {/* Mobile Menu */}
      {hamburgerOpen && (
        <motion.div
          className="lg:hidden bg-gray-800 text-white absolute top-0 right-0 w-[80%] h-[100vh] p-2"
          initial={{ opacity: 0, x: 300 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 300 }}
          transition={{ duration: 0.3 }}
        >
          <ul className="flex flex-col items-left py-3 gap-4">
            {menuItems.map((item, index) => (
              <li key={index} className="relative">
                {item.options ? (
                  <div ref={menuRef}>
                    <button
                      className="text-white py-2 rounded-md flex items-center focus:outline-none"
                      onClick={() => toggleDropdown(index)}
                    >
                      {item.title}
                      <span className="ml-2">
                        {openDropdownIndex === index ? (
                          <FiChevronUp className="w-5 h-5" />
                        ) : (
                          <FiChevronDown className="w-5 h-5" />
                        )}
                      </span>
                    </button>
                    {openDropdownIndex === index && (
                      <motion.ul
                        className="bg-gray-700 text-white mt-2 w-40 rounded-md shadow-lg py-2"
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2 }}
                      >
                        {item.options.map((option, idx) => (
                          <li key={idx}>
                            <Link
                              to={option.path}
                              className="block py-2"
                              onClick={() => {
                                setOpenDropdownIndex(null);
                                setHamburgerOpen(false);
                              }}
                            >
                              {option.title}
                            </Link>
                          </li>
                        ))}
                      </motion.ul>
                    )}
                  </div>
                ) : (
                  <Link
                    to={item.path}
                    className={`text-white p-1 block ${
                      pathname == item?.path && "bg-qlithSky"
                    } `}
                    onClick={() => setHamburgerOpen(false)}
                  >
                    {item.title}
                  </Link>
                )}
              </li>
            ))}
          </ul>
          <Link
            to="/login"
            className="flex text-white bg-qlithSky rounded items-center py-1 px-2"
          >
            <p>Login</p>
            <img src={Login} alt="" />
          </Link>
        </motion.div>
      )}
    </nav>
  );
};

// Dummy menuItems array for clarity
const menuItems = [
  {
    title: "About Us",
    path: "/about",
  },
  {
    title: "Courses",
    path: "/courses",
  },
  {
    title: "Services",
    // options: [
    //   { title: 'Service 1', path: '/service-1' },
    //   { title: 'Service 2', path: '/service-2' }
    // ]
    path: "/services",
  },
  {
    title: "Career",
    path: "/carrer",
  },
  {
    title: "Contact Us",
    path: "/contact",
  },
];

export default MainNavbar;
