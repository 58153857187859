import React from "react";
import Spinner from "./Spinner";

export default function NormalButton({
    label = "Click Me",
    color = "qlithSky",
    isLoading = false,
    onClick,
    rounded = false,
    icon = null,
    iconPosition = "left",
    type = ""  //outline
}) {
    return (
        <div
            onClick={onClick}
            className={`px-3 py-1.5 text-sm focus:outline-none transition-all ${rounded ? "rounded-full" : "rounded-sm"
                }  ${type == 'outline' ? ` border border-${color} text-${color}` : `bg-${color} text-white border border-${color}`}  cursor-pointer ${isLoading ? "cursor-not-allowed opacity-50" : "hover:bg-opacity-90"
                }`}
        >
            {isLoading ? (
                <div className="w-full h-full flex justify-center items-center" >
                    <Spinner />
                </div>
            ) : (
                <div className="flex items-center justify-center gap-1">
                    {icon && iconPosition == "left" && <span>{icon}</span>}
                    <p className="truncate ">{label}</p>
                    {icon && iconPosition == "right" && <span>{icon}</span>}
                </div>
            )}
        </div>
    );
}
