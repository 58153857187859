import React from "react";
import htmlBook from "../../../assets/home/htmlBook.svg";
import mernBook from "../../../assets/home/mernBook.svg";
import nodeBook from "../../../assets/home/nodeBook.svg";
import reactBook from "../../../assets/home/reactBook.svg";
import { Link } from "react-router-dom";

const HomeCourses = () => {
  return (
    <div className="bg-[#1A273C] py-6">
      <div className="flex items-center justify-center flex-col lg:w-[40%] md:w-[40%]  w-[80%]  m-auto">
        <h2 className="text-white text-[30px] mb-4">Our Courses</h2>
        <p className="text-white text-center">
          Develop your skills with our hands-on, practical courses, designed to
          cater to both beginners and experienced professionals looking to
          enhance their expertise in web development
        </p>
      </div>
      <div className="py-6 flex lg:flex-row md:flex-row flex-col lg:px-20 md:px-20 px-10 w-[80%] m-auto">
        <div>
          <img className="w-[250px]" src={mernBook} alt="" />
        </div>
        <div>
          <img
            className="w-[400px] relative right-6 lg:-top-0 md:-top-0 -top-28 "
            src={reactBook}
            alt=""
          />
        </div>
        <div>
          <img
            className="w-[300px] relative lg:right-10 md:right-10 right-0 -rotate-12 lg:-top-0 md:-top-0 -top-36"
            src={nodeBook}
            alt=""
          />
        </div>
        <div>
          <img
            className="w-[250px] relative rotate-12 lg:bottom-0 md:bottom-0 bottom-52"
            src={htmlBook}
            alt=""
          />
        </div>
      </div>
      <div className="flex justify-center lg:-mt-0 md:-mt-0 -mt-52">
        <Link
          to="/courses"
          className="flex justify-center items-center text-white hover:text-qlithSky mb-6 cursor-pointer w-52 border-b p-2 hover:border-qlithSky transition-all duration-700"
        >
          <button>Explore All the Courses</button>
        </Link>
      </div>
    </div>
  );
};

export default HomeCourses;
