import React from "react";

export default function Table({ columns, data, loading }) {
  return (
    <div className="overflow-x-auto max-h-[80vh]">
      <table className="min-w-full border border-gray-300">
        <thead className="bg-gray-100">
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                className="sticky top-0 border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold bg-qlithSky text-white"
              >
                {typeof column.headerAction === "function" ? column.headerAction() : column.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="overflow-y-scroll">
          {loading ? (
            // Render skeleton rows when loading
            Array(5)
              .fill()
              .map((_, rowIndex) => (
                <tr key={rowIndex} className="even:bg-gray-50">
                  {columns.map((_, colIndex) => (
                    <td
                      key={colIndex}
                      className="border-b border-gray-300 px-4 py-2 text-sm text-gray-600"
                    >
                      <div className="h-4 bg-gray-300 rounded animate-pulse"></div>
                    </td>
                  ))}
                </tr>
              ))
          ) : (
            // Render data rows when not loading
            data.map((row, rowIndex) => (
              <tr key={rowIndex} className="even:bg-gray-50">
                {columns.map((column, colIndex) => (
                  <td
                    key={colIndex}
                    className="border-b border-gray-300 px-4 py-2 text-sm text-gray-600"
                  >
                    {column.action ? column.action(row) : row[column.key]}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}
