import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import carrerbanner from "../../../assets/banner-bg/carrer.jpg";
import { motion } from "framer-motion";
import { IoBagOutline } from "react-icons/io5";
import { SlLocationPin } from "react-icons/sl";
import data from "./carrerData.json";

export default function CareerPage() {
  const [hoveredCourseIndex, setHoveredCourseIndex] = useState(null);
  const navigate = useNavigate();

  return (
    <div className="bg-black">
      <div className="h-[500px] bg-black absolute w-full bg-opacity-70"></div>
      <div
        className="bg-cover bg-opacity-65 bg-fixed h-[500px] flex justify-center items-center flex-col gap-3 bg-black"
        style={{
          backgroundImage: `url(${carrerbanner})`,
        }}
      >
        <div className="text-white md:w-2/3 w-[90%] flex justify-center items-center flex-col   relative z-10">
          <h1 className=" md:text-[50px] text-[30px] tracking-wider">
            Join Our Team
          </h1>
          <p className="text-center mb-2">
            Join a dynamic team of innovators and creatives, and help us shape
            the future of technology <br /> while growing your career in a
            collaborative, growth-oriented environment
          </p>
          <Link
            to={`/contact`}
            className="group flex items-center justify-center lg:w-1/2 md:w-full text-white py-1 px-4 rounded-sm border border-[#26B6CE] transition-all duration-300 bg-[#26B6CE] hover:bg-transparent"
          >
            Get in touch
            <FaArrowRightLong className="ml-2 transform group-hover:translate-x-1 transition-transform duration-300" />
          </Link>
        </div>
      </div>

      {/* Main Content */}
      <div className="min-h-screen bg-gradient-to-t from-[#25B7CE] to-[#1A273C] py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
            {data?.carrer?.map((course, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
                className="cursor-pointer"
                onClick={() => {
                  navigate(`/carrer/${course?.id}`);
                }}
              >
                <div
                  className="bg-white rounded-xl shadow-lg overflow-hidden h-full transition-all duration-300 hover:shadow-2xl hover:scale-105 transform"
                  onMouseEnter={() => setHoveredCourseIndex(index)}
                  onMouseLeave={() => setHoveredCourseIndex(null)}
                >
                  <div className="relative">
                    <img
                      src={course.image}
                      alt={course.title}
                      className="w-full h-48 object-cover"
                    />
                    <div className="absolute top-2 left-2 bg-white px-3 py-1 rounded-full text-sm font-semibold text-gray-700 shadow-md">
                      {course.category}
                    </div>
                  </div>
                  <div className="p-6">
                    <h2 className="text-xl font-bold text-gray-900 mb-2">
                      {course.title}
                    </h2>
                    <p className="text-sm text-gray-600 mb-4">
                      {course.smallDescription}
                    </p>
                    <div className="flex items-end justify-between">
                      <div>
                        <div className="flex gap-1">
                          <span>
                            <IoBagOutline />
                          </span>
                          <p>{course?.jobType}</p>
                        </div>

                        <div className="flex gap-1">
                          <span>
                            <SlLocationPin />
                          </span>
                          <p>{course?.location}</p>
                        </div>
                      </div>
                      <motion.div
                        initial={{ opacity: 0, x: 10 }}
                        animate={{
                          opacity: hoveredCourseIndex === index ? 1 : 0,
                          x: hoveredCourseIndex === index ? 0 : -10,
                        }}
                        transition={{ duration: 0.2 }}
                        className="text-right"
                      >
                        <button className="text-qlithSky">
                          <FaArrowRightLong />
                        </button>
                      </motion.div>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
