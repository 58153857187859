import React, { useEffect, useState } from 'react'
import Edit from '../../assets/Icons/Edit';
import Delete from '../../assets/Icons/Delete';
import axios from 'axios';
import List from '../../components/library/List'
import { toast } from 'react-toastify';
import ConfirmationModal from '../../components/library/modal/ConfirmationModal';

const Dashboard = () => {
  const [students, setStudents] = useState([])
  const [showFrom, setShowFrom] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState("")
  const [delLoad, setDelLoad] = useState(false)
  const [getUserLoad, setGetUserLoad] = useState(false)

  const getUserData = async () => {
    setGetUserLoad(true)
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/get-all-users`);
    if (response?.data) {
      setStudents(response?.data)
    }
    setGetUserLoad(false)
  }

  useEffect(() => {
    getUserData();
  }, [])

  const columns = [
    { name: 'Name', key: 'name' },
    { name: 'Email', key: 'email' },
    { name: 'Phone', key: 'phoneNumber' },
    { name: 'Role', key: 'role' },
    {
      name: 'Edit',
      // headerAction: () => (
      //   <div className="flex items-center gap-2" >
      //     <span>Actions</span>
      //     <Arrow />
      //   </div>
      // ),
      action: (row) => (
        <button onClick={() => alert(`Action clicked for ${row.name}`)}>
          <Edit />
        </button>
      )
    },
    {
      name: 'Delete',
      // headerAction: () => (
      //   <div className="flex items-center gap-2" >
      //     <span>Actions</span>
      //     <Arrow />
      //   </div>
      // ),
      action: (row) => (
        <button onClick={() => {
          setId(row?._id)
          setIsModalOpen(true)
        }}>
          <Delete />
        </button>
      )
    }
  ];


  const handleConfirm = async () => {
    setDelLoad(true)
    const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/users/${id}`);
    if (response?.data?.status) {
      toast.success(response?.data?.message)
      setIsModalOpen(false);
      getUserData()
    } else {
      toast.error("Something went wrong")
      setIsModalOpen(false);
    }
    setDelLoad(false)
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setId("")
  };

  return (
    <div >
      <ConfirmationModal
        loading={delLoad}
        isOpen={isModalOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        title="Delete User"
        message="Are you sure you want to delete this user? This action cannot be undone."
      />
      <div className='p-2' >
        <h2 className='text-qlithNavy font-semibold' >Users</h2>
        <List columns={columns} data={students} loading={getUserLoad} />
      </div>
    </div>
  )
}

export default Dashboard
