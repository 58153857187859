import React from 'react';

export default function TextArea({ label = "input", disabled = false, value = "", onchange, rows = 3 }) {
    return (
        <div>
            <p className='text-sm text-qlithNavy'>{label}</p>
            <textarea
                rows={rows}
                className='border-b outline-none w-full text-gray-700'
                value={value}
                disabled={disabled}
                onChange={(e) => onchange(e.target.value)} />

        </div>
    );
}
