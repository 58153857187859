import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import mongoDb from "../../../assets/home/Technology/mongoDb.webp";
import react from "../../../assets/home/Technology/react.webp";
import js from "../../../assets/home/Technology/js.webp";
import node from "../../../assets/home/Technology/node.png";
import express from "../../../assets/home/Technology/express.png";
import html from "../../../assets/home/Technology/html.png";
import css from "../../../assets/home/Technology/css.png";
import tailwind from "../../../assets/home/Technology/tailwind.svg";
import chakra from "../../../assets/home/Technology/chakra.png";
import vercel from "../../../assets/home/Technology/vercel.svg";
import render from "../../../assets/home/Technology/render.webp";
import mui from "../../../assets/home/Technology/mui.png";
import ts from "../../../assets/home/Technology/ts.webp";
import postman from "../../../assets/home/Technology/postman.webp";
import cloudinary from "../../../assets/home/Technology/cloudinary.webp";
import netlify from "../../../assets/home/Technology/netlify.png";
import bootstrap from "../../../assets/home/Technology/bootstrap.png";
import nextjs from "../../../assets/home/Technology/nextjs.svg";
import npm from "../../../assets/home/Technology/npm.png";
import code_sandbox from "../../../assets/home/Technology/code-sandbox.png";
import { Link } from "react-router-dom";

function NoBtn() {
  return <div className="hidden" />;
}

function ToLeft({ row }) {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    nextArrow: <NoBtn />,
    prevArrow: <NoBtn />,
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        {row &&
          row?.map((ele) => (
            <Link to={ele?.link} target="_blank">
              <div className="bg-white shadow-lg p-1 md:p-3 lg:p-3  m-1 md:m-2 rounded md:rounded-lg lg:rounded-xl block md:flex lg:flex gap-4 items-center">
                <img
                  className="h-8 md:h-10 m-auto md:m-0 lg:m-0 "
                  src={ele?.img}
                  alt="logo"
                />
                <p className="hidden sm:block text-sm sm:text-base font-medium truncate">
                  {ele?.name}
                </p>
              </div>
            </Link>
          ))}
      </Slider>
    </div>
  );
}

function ToRight({ row }) {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    rtl: true,
    nextArrow: <NoBtn />,
    prevArrow: <NoBtn />,
  };
  return (
    <div className="slider-container">
      <Slider {...settings} className="">
        {row &&
          row?.map((ele) => (
            <Link to={ele?.link} target="_blank">
              <div className="bg-white shadow-lg p-1 md:p-3 lg:p-3  m-1 md:m-2 rounded md:rounded-lg lg:rounded-xl block md:flex lg:flex gap-4 items-center">
                <img
                  className="h-8 md:h-10 m-auto md:m-0 lg:m-0 "
                  src={ele?.img}
                  alt="logo"
                />
                <p className="hidden sm:block text-sm sm:text-base font-medium truncate">
                  {ele?.name}
                </p>
              </div>
            </Link>
          ))}
      </Slider>
    </div>
  );
}

function Technology() {
  const row1 = [
    {
      name: "React",
      img: react,
      link: "https://react.dev/",
    },
    {
      name: "Mongo DB",
      img: mongoDb,
      link: "https://www.mongodb.com/",
    },
    {
      name: "Express Js",
      img: express,
      link: "https://expressjs.com/",
    },
    {
      name: "Node Js",
      img: node,
      link: "https://nodejs.org/en",
    },
    {
      name: "HTML",
      img: html,
      link: "https://developer.mozilla.org/en-US/docs/Web/HTML",
    },
    {
      name: "CSS",
      img: css,
      link: "https://developer.mozilla.org/en-US/docs/Web/CSS",
    },
    {
      name: "Java Script",
      img: js,
      link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
    },
  ];
  const row2 = [
    {
      name: "Chakra UI",
      img: chakra,
      link: "https://www.chakra-ui.com/",
    },
    {
      name: "Tailwind CSS",
      img: tailwind,
      link: "https://tailwindcss.com/",
    },
    {
      name: "Netlify",
      img: netlify,
      link: "https://www.netlify.com/",
    },
    {
      name: "Vercel",
      img: vercel,
      link: "https://vercel.com/",
    },
    {
      name: "MUI",
      img: mui,
      link: "https://mui.com/",
    },
    {
      name: "Type Script",
      img: ts,
      link: "https://www.typescriptlang.org/",
    },
    {
      name: "Code Sandbox",
      img: code_sandbox,
      link: "https://codesandbox.io/",
    },
  ];

  const row3 = [
    {
      name: "Postman",
      img: postman,
      link: "https://www.postman.com/",
    },
    {
      name: "Cloudinary",
      img: cloudinary,
      link: "https://cloudinary.com/home",
    },
    {
      name: "Bootstrap",
      img: bootstrap,
      link: "https://getbootstrap.com/",
    },
    {
      name: "Next Js",
      img: nextjs,
      link: "https://nextjs.org/",
    },
    {
      name: "NPM",
      img: npm,
      link: "https://www.npmjs.com/",
    },
    {
      name: "Render",
      img: render,
      link: "https://render.com/",
    },
  ];

  return (
    <div className="bg-qlithSky py-2 md:py-6">
      <h1 className=" text-center text-xl md:text-[30px]  mb-6 text-white">
        Innovative Technologies We Use
      </h1>
      <ToLeft row={row1} />
      <ToRight row={row2} />
      <ToLeft row={row3} />
    </div>
  );
}

export default Technology;
