import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { RiMenu4Line } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import encryptionModule from './LocalStorageUtils';
import NormalButton from '../library/NormalButton';
import Arrow from '../../assets/Icons/Arrow';
import Drawer from '../../assets/Icons/Drawer';



const Sidebar = () => {
    const navigate = useNavigate();
    const path = useLocation();
    const [show, setShow] = useState(true)
    const [user, setUser] = useState({})
    const [options, setOptions] = useState([])


    const items = [
        { name: "Dashboard", icon: "📊", path: "/dashboard" },
        { name: "Students", icon: "👨‍🎓", path: "/students" },
        { name: "Income", icon: "💰", path: "/income" },
        { name: "Expenditure", icon: "📉", path: "/expenditure" },
        { name: "Courses", icon: "📘", path: "/courses-dashboard" },
        { name: "Employees", icon: "👨‍💼", path: "/employees" },
        { name: "Settings", icon: "⚙️", path: "/settings" },
    ];

    const initialData = () => {
        const userLs = encryptionModule.becryptData('user');
        const sidebarPermissionsLs = userLs?.sidebarPermissions;
        console.log(sidebarPermissionsLs);

        const filteredItems = items.filter((item) => sidebarPermissionsLs[item.name]).map((item) => {
            return {
                name: item.name,
                icon: item.icon,
                path: item.path,
            };
        });
        setOptions(filteredItems)
    }

    useEffect(() => {
        initialData()
    }, [])


    return (
        <div className={`${show ? 'w-40' : 'w-12'} h-full bg-qlithSky`}  >
            <div className='h-[7%]' >
                <NormalButton label={show ? "Hide" : ""} icon={<Drawer rotate={show ? 'left' : 'right'} />} onClick={() => setShow(!show)} iconPosition='right' />
            </div>
            <ul className='h-[86%] overflow-auto text-white' >
                {
                    options.map((ele) =>
                        <li onClick={() => navigate(ele?.path)} className={`${path?.pathname == ele.path && "bg-qlithNavy"} w-full flex gap-2 cursor-pointer px-4 mb-3`} >
                            <p  >{ele.icon}</p>
                            {show && <p >{ele.name}</p>}
                        </li>
                    )
                }
            </ul>
            <div className='h-[7%]' >
                <NormalButton label={show ? "Log in" : ""} icon={<Arrow rotate='left' />} iconPosition='left' />
            </div>
        </div>
    );
};

export default Sidebar;
