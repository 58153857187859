import React, { useEffect, useState } from "react";
import carrerbanner from "../../../assets/banner-bg/apply.jpg";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link, useParams } from "react-router-dom";
import data from "./carrerData.json";

export default function Apply() {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    fullName: "",
    higherQualification: "",
    passingYear: "",
    totalExperience: "",
    email: "",
    phoneNumber: "",
    resumeLink: "",
  });

  const { id } = useParams();
  const [jobDetails, setJobDetails] = useState({});

  console.log(jobDetails);

  useEffect(() => {
    const job = data?.carrer?.filter((ele) => {
      return ele?.id == id;
    });
    setJobDetails(job?.[0]);
  }, [id]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    // Basic validation
    if (!formData.fullName) newErrors.fullName = "Full Name is required";
    if (!formData.higherQualification)
      newErrors.higherQualification = "Higher Qualification is required";
    if (!formData.passingYear)
      newErrors.passingYear = "Passing Year is required";
    if (!formData.totalExperience)
      newErrors.totalExperience = "Total Experience is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.phoneNumber)
      newErrors.phoneNumber = "Phone Number is required";
    if (!formData.resumeLink) newErrors.resumeLink = "Resume Link is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      console.log("Form data submitted:", formData);
      // Reset the form after successful submission (optional)
      setFormData({
        fullName: "",
        higherQualification: "",
        passingYear: "",
        totalExperience: "",
        email: "",
        phoneNumber: "",
        resumeLink: "",
      });
    }
  };

  return (
    <div>
      <div className="h-[300px] bg-black absolute w-full bg-opacity-70"></div>
      <div
        className="bg-cover bg-opacity-65 bg-fixed h-[300px] flex justify-center items-center flex-col gap-3 bg-black"
        style={{
          backgroundImage: `url(${carrerbanner})`,
        }}
      >
        <div className="text-white md:w-2/3 w-[90%] flex justify-center items-center flex-col   relative z-10">
          <h1 className=" md:text-[50px] text-[30px] tracking-wider">
            {jobDetails?.title}
          </h1>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className="p-8 bg-white -lg shadow-md grid grid-cols-1 md:grid-cols-2  lg:grid-cols-2  gap-x-10"
      >
        <div className="mb-4 h-16">
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            placeholder="Full Name"
            className="w-full  py-2 border-b border-gray-300  focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
          {errors.fullName && (
            <span className="text-red-500 text-sm">{errors.fullName}</span>
          )}
        </div>
        {/* Repeat the above structure for other fields */}
        <div className="mb-4 h-16">
          <input
            type="text"
            id="higherQualification"
            name="higherQualification"
            value={formData.higherQualification}
            onChange={handleChange}
            placeholder="Higher Qualification"
            className="w-full  py-2 border-b border-gray-300  focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
          {errors.higherQualification && (
            <span className="text-red-500 text-sm">
              {errors.higherQualification}
            </span>
          )}
        </div>
        <div className="mb-4 h-16">
          <input
            type="date"
            id="passingYear"
            name="passingYear"
            value={formData.passingYear}
            onChange={handleChange}
            placeholder="Passing Year"
            className="w-full  py-2 border-b border-gray-300  focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
          {errors.passingYear && (
            <span className="text-red-500 text-sm">{errors.passingYear}</span>
          )}
        </div>
        <div className="mb-4 h-16">
          <input
            type="number"
            id="totalExperience"
            name="totalExperience"
            value={formData.totalExperience}
            onChange={handleChange}
            placeholder="Total Years of Experience"
            className="w-full  py-2 border-b border-gray-300  focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
          {errors.totalExperience && (
            <span className="text-red-500 text-sm">
              {errors.totalExperience}
            </span>
          )}
        </div>
        <div className="mb-4 h-16">
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            className="w-full  py-2 border-b border-gray-300  focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
          {errors.email && (
            <span className="text-red-500 text-sm">{errors.email}</span>
          )}
        </div>
        <div className="mb-4 h-16">
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder="Phone Number"
            className="w-full  py-2 border-b border-gray-300  focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
          {errors.phoneNumber && (
            <span className="text-red-500 text-sm">{errors.phoneNumber}</span>
          )}
        </div>
        <div className="mb-4 h-16">
          <input
            type="url"
            id="resumeLink"
            name="resumeLink"
            value={formData.resumeLink}
            onChange={handleChange}
            placeholder="Resume Link (Google Drive)"
            className="w-full  py-2 border-b border-gray-300  focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
          {errors.resumeLink && (
            <span className="text-red-500 text-sm">{errors.resumeLink}</span>
          )}
        </div>

        <div>
          <button
            type="submit"
            className="group flex w-full items-center justify-center  text-white py-1 px-4 rounded-sm border border-[#26B6CE] transition-all duration-300 bg-[#26B6CE] hover:text-[#26B6CE] hover:bg-transparent"
          >
            Submit Application
            <FaArrowRightLong className="ml-2 transform group-hover:translate-x-1 transition-transform duration-300" />
          </button>
        </div>
      </form>
    </div>
  );
}
