import React, { useEffect, useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link, useParams } from "react-router-dom";
import jobDetailsBanner from "../../../assets/banner-bg/jobDetails.jpg";
import data from "./carrerData.json";
import { motion } from "framer-motion";
import { LuDot } from "react-icons/lu";

export default function JobDetails() {
  const { id } = useParams();
  const [jobDetails, setJobDetails] = useState({});

  useEffect(() => {
    const job = data?.carrer?.filter((ele) => {
      return ele?.id == id;
    });
    setJobDetails(job?.[0]);
  }, [id]);

  return (
    <div>
      <div className="h-[500px] bg-black absolute w-full bg-opacity-70"></div>
      <div
        className="bg-cover bg-opacity-65 bg-fixed h-[500px] flex justify-center items-center flex-col gap-3 bg-black"
        style={{
          backgroundImage: `url(${jobDetailsBanner})`,
        }}
      >
        <div className="text-white md:w-2/3 w-[90%] flex justify-center items-center flex-col   relative z-10">
          <h1 className=" md:text-[50px] text-[30px] tracking-wider text-center">
            {jobDetails?.title}
          </h1>
          <p className="text-center mb-2">{jobDetails?.jobDescription}</p>

          <Link
            to={`/carrer/${jobDetails?.id}/apply`}
            className="group flex items-center justify-center lg:w-1/2 md:w-full text-white py-1 px-4 rounded-sm border border-[#26B6CE] transition-all duration-300 bg-[#26B6CE] hover:bg-transparent"
          >
            Apply
            <FaArrowRightLong className="ml-2 transform group-hover:translate-x-1 transition-transform duration-300" />
          </Link>
        </div>
      </div>

      <div className="border grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-10 gap-7 text-sm ">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.1 }}
          whileHover={{ scale: 1.1 }}
          className="shadow-lg p-4 rounded"
        >
          <p className="font-semibold text-lg mb-2 text-qlithSky">
            Responsibilities
          </p>
          <div>
            {jobDetails?.responsibilities?.map((ele) => (
              <div className="flex gap-2 items-start">
                <span className="text-qlithNavy">
                  <LuDot />
                </span>
                <p>{ele}</p>
              </div>
            ))}
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.2 }}
          whileHover={{ scale: 1.1 }}
          className="shadow-lg p-4 rounded"
        >
          <p className="font-semibold text-lg mb-2 text-qlithSky">
            Requirements
          </p>
          <div>
            {jobDetails?.requirements?.map((ele) => (
              <div className="flex gap-2 items-start">
                <span className="text-qlithNavy">
                  <LuDot />
                </span>
                <p>{ele}</p>
              </div>
            ))}
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
          whileHover={{ scale: 1.1 }}
          className="shadow-lg p-4 rounded"
        >
          <p className="font-semibold text-lg mb-2 text-qlithSky">Benefits</p>
          <div>
            {jobDetails?.benefits?.map((ele) => (
              <div className="flex gap-2 items-start">
                <span className="text-qlithNavy">
                  <LuDot />
                </span>
                <p>{ele}</p>
              </div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
}
