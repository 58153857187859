import React from 'react';

export default function Drawer({ rotate = "up", size = "20", className = "" }) {
    const directionMap = {
        left: "rotate-90",
        right: "-rotate-90",
        up: "rotate-0",
        down: "rotate-180",
    };

    const rotationClass = directionMap[rotate] || "rotate-0";

    return (
        <div className={`transform ${rotationClass}`}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className={`size-6 ${className}`}
                style={{ width: size, height: size }}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 5.25L12 12.75l7.5-7.5M4.5 11.25l7.5 7.5 7.5-7.5"
                />
            </svg>
        </div>
    );
}
