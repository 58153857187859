import React, { useState } from "react";
import { IoCallOutline, IoLocationOutline } from "react-icons/io5";
import { IoMailOutline } from "react-icons/io5";
import contactUsBanner from "../../../assets/contact/contactUs.png";
import contactUsPhone from "../../../assets/contact/contactUsPhone.png";
import { toast } from "react-toastify";
import axios from "axios";
import Spinner from "../../../components/library/Spinner";
import contact from "../../../assets/banner-bg/contact.jpg";
import { FaArrowRightLong } from "react-icons/fa6";

const Contact = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const [formDate, setFormDate] = useState({
    fullName: "",
    phone: "",
    email: "",
    description: "",
  });

  const handelChange = (key, value) => {
    setFormDate((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handelContact = async (e) => {
    e.preventDefault();
    if (
      !formDate.fullName ||
      !formDate.phone ||
      !formDate.email ||
      !formDate.description
    ) {
      toast.warning("Please all the fields");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${baseUrl}/contact/contact-us`,
        formDate
      );
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        setFormDate({
          fullName: "",
          phone: "",
          email: "",
          description: "",
        });
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-black">
      {/* Hero Section with Background */}
      <div className="h-[500px] bg-black absolute w-full bg-opacity-70"></div>
      <div
        className="bg-cover bg-opacity-65 bg-fixed h-[500px] flex justify-center items-center flex-col gap-3 bg-black"
        style={{
          backgroundImage: `url(${contact})`,
        }}
      >
        <div className="text-white md:w-2/3 w-[90%] flex justify-center items-center flex-col   relative z-10">
          <h1 className=" md:text-[50px] text-[30px] tracking-wider">
            Get in Touch
          </h1>
          <p className="text-center mb-2">
            We'd love to hear from you! Whether you have questions about our
            services, want to collaborate, or need support with your project,
            our team is here to help. At Qlith Innovation, we value every
            opportunity to connect with clients, partners, and curious learners.
            Reach out to us for any inquiries, and let's discuss how we can work
            together to achieve your digital and educational goals.
          </p>
        </div>
      </div>

      {/* Contact Info and Form Section */}
      <div className="bg-black text-white px-5 lg:px-20 py-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 rounded p-10 bg-gradient-to-b from-black to-[#26B6CE]">
          {/* Contact Info */}
          <div className="bg-white text-gray-700 rounded-lg shadow-md p-6 space-y-4">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">
            Contact information
            </h2>
            <div className="space-y-4">
              <div className="flex items-center space-x-4">
                <div className="bg-qlithSky rounded-full p-2">
                  <IoCallOutline className="text-white text-xl" />
                </div>
                <a
                  href="tel:+917848009865"
                  className="text-gray-600 hover:text-qlithSky transition-all duration-700"
                >
                  +91 7848009865
                </a>
              </div>
              <div className="flex items-center space-x-4">
                <div className="bg-qlithSky rounded-full p-2">
                  <IoMailOutline className="text-white text-xl" />
                </div>
                <a
                  href="mailto:official@qlith.com"
                  className="text-gray-600 hover:text-qlithSky transition-all duration-700"
                >
                  official@qlith.com
                </a>
              </div>
              <div className="flex items-center space-x-4">
                <div className="bg-qlithSky rounded-full p-2">
                  <IoLocationOutline className="text-white text-xl" />
                </div>
                <span className="text-gray-600 hover:text-qlithSky transition-all duration-700">
                  Plot No : 841 , Rasulgarh (Infront of Esplanade), <br />{" "}
                  Bhubaneswar - 10
                </span>
              </div>
            </div>
          </div>

          {/* Contact Form */}
          <form
            onSubmit={(e) => handelContact(e)}
            className="bg-white text-gray-700 rounded-lg shadow-md p-6 space-y-4"
          >
            <h2 className="text-2xl font-semibold text-gray-800">
              Send us a message
            </h2>
            <input
              type="text"
              placeholder="Full name"
              value={formDate?.fullName}
              className="w-full py-2 border-b border-gray-300 outline-none focus:border-qlithSky"
              onChange={(e) => handelChange("fullName", e.target.value)}
            />
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <input
                type="email"
                placeholder="Email"
                value={formDate?.email}
                className="w-full py-2 border-b border-gray-300 outline-none focus:border-qlithSky"
                onChange={(e) => handelChange("email", e.target.value)}
              />
              <input
                type="tel"
                placeholder="Phone"
                maxLength={10}
                value={formDate?.phone}
                className="w-full py-2 border-b border-gray-300 outline-none focus:border-qlithSky"
                onChange={(e) => handelChange("phone", e.target.value)}
              />
            </div>
            <textarea
              rows={4}
              placeholder="Your message"
              value={formDate?.description}
              className="w-full py-2 border-b border-gray-300 outline-none focus:border-qlithSky"
              onChange={(e) => handelChange("description", e.target.value)}
            ></textarea>
            <button
              disabled={loading}
              type="submit"
              className="bg-qlithSky text-white py-1 w-full hover:rounded font-semibold mt-12 flex h-9 justify-center items-center border border-qlithSky hover:bg-white transition-all duration-700 hover:text-qlithSky"
            >
              {loading ? <Spinner /> : "Send Message"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
