import React from 'react';

export default function NormalInput({ label = "input", disabled = false, value = "", onchange, type = "string" }) {
    return (
        <div>
            <p className='text-sm text-qlithNavy'>{label}</p>
            <input
                type={type}
                className='border-b outline-none w-full text-gray-700'
                value={value}
                disabled={disabled}
                onChange={(e) => onchange(e.target.value)}
            />
        </div>
    );
}
