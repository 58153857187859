import React from "react";
import servicesBanner from "../../../assets/services/servicesBanner.png";
import servicesBannerPhone from "../../../assets/services/servicesBannerPhone.png";
import services from "../../../assets/banner-bg/services.jpg";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

function Services() {
  return (
    <div>
      <div className="h-[500px] bg-black absolute w-full bg-opacity-70"></div>
      <div
        className="bg-cover bg-opacity-65 bg-fixed h-[500px] flex justify-center items-center flex-col gap-3 bg-black"
        style={{
          backgroundImage: `url(${services})`,
        }}
      >
        <div className="text-white md:w-2/3 w-[90%] flex justify-center items-center flex-col   relative z-10">
          <h1 className=" md:text-[50px] text-[30px] tracking-wider">
            What We Offer
          </h1>
          <p className="text-center mb-2">
            Transform your business with our tailored services, offering
            innovative web and app development, <br /> UI/UX design, and more to
            drive success and growth
          </p>

          <Link
            to={`/contact`}
            className="group flex items-center justify-center lg:w-1/2 md:w-full text-white py-1 px-4 rounded-sm border border-[#26B6CE] transition-all duration-300 bg-[#26B6CE] hover:bg-transparent"
          >
            Get in touch
            <FaArrowRightLong className="ml-2 transform group-hover:translate-x-1 transition-transform duration-300" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Services;
